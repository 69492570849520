import { useMediaQuery } from "react-responsive"

export const useMobileSize = () => {
  const isMobile = useMediaQuery({ maxWidth: "768px" })
  const isTablet = useMediaQuery({ maxWidth: "1024px" })
  const isLaptopMax = useMediaQuery({ maxWidth: "1280px" })
  const isLaptopMin = useMediaQuery({ minWidth: "1024px" })
  const isDesktop = useMediaQuery({ minWidth: "1280px" })

  return { isTablet, isLaptopMax, isLaptopMin, isDesktop, isMobile }
}
