import React, { FC } from "react"
import Typography from "src/views/components/typography"
import { DraftConverter } from "@tiptap/draftjs-to-tiptap"
import { Editor, generateHTML } from "@tiptap/core"
import StarterKit from "@tiptap/starter-kit"
import { useTranslation } from "next-i18next"
import Link from "@tiptap/extension-link"
import { EditorState } from "draft-js"
import TextAlign from "@tiptap/extension-text-align"
import Bold from "@tiptap/extension-bold"
import Italic from "@tiptap/extension-italic"
import Underline from "@tiptap/extension-underline"
import Strike from "@tiptap/extension-strike"

// before september descriptions were send in this format using draftJS
// you can recognize if description is in draftJS json format if it starts with {"blocks"
// we moved to never tiptap.js which has HTML format and is easier to manage

interface Props {
  description?: string
}
const RenderDescription: FC<Props> = ({ description }) => {
  const { t } = useTranslation("common")
  if (!description) {
    return null
  }
  // If this is draft JS
  if (description.startsWith(`{"blocks"`) || description.startsWith(`{\"blocks}`)) {
    const convertDraftToTiptap = new DraftConverter()
    const tiptapContent = convertDraftToTiptap.convert(JSON.parse(description))

    const htmlContent = generateHTML(tiptapContent, [StarterKit, Bold, Italic, Underline, Strike, Link])

    return (
      <div className="mt-5 flex flex-col gap-4">
        <Typography type="h5" weight="medium">
          {t("VACANCY_DESCRIPTION")}
        </Typography>
        <Typography type="text" weight="medium" className="mb-4 vacancy-description whitespace-pre-line">
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </Typography>
        {/* <Editor editorState={editorState} onChange={() => {}} readOnly /> */}
      </div>
    )
  }

  return (
    <div className="mt-5 flex flex-col gap-4">
      <Typography type="h5" weight="medium">
        {t("VACANCY_DESCRIPTION")}
      </Typography>
      <Typography
        type="text"
        weight="medium"
        className="mb-4 vacancy-description whitespace-pre-line vacancy-description"
      >
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Typography>
    </div>
  )
}

export default RenderDescription

//   let editorState = EditorState.createEmpty()

//   try {
//     const contentState = convertFromRaw(JSON.parse(vacancy.description))
//     console.log("🚀 ~ JSON.parse(vacancy.description):", JSON.parse(vacancy.description))
//     console.log("🚀 ~ vacancy.description:", vacancy.description)
//     editorState = EditorState.createWithContent(contentState)
//   } catch (error) {
//     console.log("Error parsing description:", vacancy.id)
//   }

//     {editorState.getCurrentContent().hasText() && (
//         <div className="mt-5 flex flex-col gap-4">
//           <Typography type="h5" weight="medium">
//             ვაკანსიის აღწერა
//           </Typography>
//           <Editor editorState={editorState} onChange={() => {}} readOnly />
//         </div>
//       )}
//   return (
//     <div>RenderDescription</div>
//   )
